body #root {
  width: 100%;
}
body.home-layout {
  background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url('./images/background.jpg') center center no-repeat;
  background-size: cover; display: flex; min-height: 100vh;

  main {
    width: 100%; position: relative; z-index: 500;
  }

  .content {
    width: 100%; max-width: 1000px; margin: auto;
  }
}

#home-wrapper {
  padding: 0 0;  width: 100%; padding-top: 180px; min-height: 100vh; display: flex; flex-direction: column;

  header {
    width: 100%; position: relative; z-index: 500; position: fixed; 
    left: 0; right: 0; top: 0; padding: 0 20px; z-index: 5000; padding-top: 30px;
    background: linear-gradient(180deg, #110834 0%, rgba(16, 19, 44, 0) 100%);
    &:before {
      content: ''; width: 100%; position: absolute; left: 0px; right: 0px; top: 0px;
      height: 300px; background: linear-gradient(180deg, #110834 0%, rgba(16, 19, 44, 0) 100%);
    }
    .content {
      position: relative;
    }
    .menu-wrapper {
      display: flex; width: 100%; align-items: center;
      margin-bottom: 30px;
      a.btn-signin {
        color: #FFF; font-size: 13px; margin-right: 15px;
        margin-left: auto;
      }
      a.btn-signup {
        margin-left: auto; float: right; width: 120px; 
        border-radius: 86px; text-align: center; flex: 0 0 120px; 
        margin-bottom: 0; margin-left: 0px;
        font-size: 12px;
        &.btn-practice {
          flex: 0 0 150px; width: 150px;
        }
      }
    }
    menu {
      display: flex; align-items: center; grid-gap: 20px;
      width: 100%;
      a {
        color: #FFF; text-decoration: none; margin-bottom: 0px;
      }
    }

    .nav-toggle { 
      -webkit-tap-highlight-color: transparent; -webkit-touch-callout: none; -webkit-user-select: none; 
      -moz-user-select: none; -ms-user-select: none; user-select: none; position: relative; 
      background: transparent; width: 28px; top: 48px;  float: right; display: none; cursor: pointer; 
      right: 30px; position: fixed; z-index: 5000;
      div {
        -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); 
        -webkit-transition: all 0.4s ease; transition: all 0.4s ease; margin-bottom: 6px; 
        background: #fff; height: 2px; width: 28px; background: #5885f7;
      }
    }
    &.active {
      .nav-toggle {
        div:nth-child(1) {
          -webkit-transform: rotate(45deg) translate3d(3px, 8px, 0);
          transform: rotate(45deg) translate3d(3px, 8px, 0);
        }
        div:nth-child(2) {
          opacity: 0;
        }
        div:nth-child(3) {
          -webkit-transform: rotate(-45deg) translate3d(3px, -8px, 0);
          transform: rotate(-45deg) translate3d(3px, -8px, 0);
        }
      }
    }


    .logo {
      width: 283px; height: 108px; margin-right: 50px;
      background: url('./images/logo.svg'); flex: 0 0 283px;
      background-size: contain; background-repeat: no-repeat;
      margin-bottom: 0px;
    }
  }

  #home {
    display: flex; flex-direction: row; grid-gap: 40px;
    align-items: center; margin-bottom: 100px;
    #practice .timer-wrapper .timer {
      max-width: 100%; position: relative;
      &:before {
        content: ''; width: 183px; height: 52px;
        background: url('./images/ico-useme.svg') center center no-repeat;
        position: absolute; right: 0px; top: -20px;
        background-size: contain;
      }
    }
    h2 {
      margin-bottom: 5px;
    }
    .half {
      width: 100%;
      img {
        max-width: 420px;
      }
      .timer {
        position: relative; background: #041246;
        padding: 60px 40px 10px 40px; border-radius: 12px;
        &::after {
          content: ''; width: 219px; height: 199px;
          background: url('./images/shape-home.svg') center center no-repeat;
          background-size: contain; position: absolute; 
          bottom: -145px; right: 0px;
        }
      }
    }
  }

  #features {
    display: flex; flex-direction: column; align-items: center;
    margin-bottom: 100px; width: 100%;
    .intro {
      max-width: 600px; text-align: center; margin-bottom: 20px;
    }
    h3, h4 {
      margin-bottom: 5px;
    }
    .feature-list {
      display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 20px;
      .feature {
        padding: 20px;
      }
    }
  }

  #plans {
    display: flex; flex-direction: column; align-items: center;
    margin-bottom: 100px; width: 100%;
    .intro {
      max-width: 600px; text-align: center; margin-bottom: 20px;
    }
    h3, h4 {
      margin-bottom: 5px;
    }
    .subscriptions {
      display: flex; grid-gap: 30px; align-items: center;
      .sub {
        display: flex; flex-direction: column; justify-content: center; 
        align-items: center; padding: 50px 20px;
        background: #001D59; box-shadow: 0px 46px 29.1px rgba(0, 0, 0, 0.5); 
        border-radius: 24px; width: 320px; text-align: center;
        -webkit-transition: all 0.4s ease; transition: all 0.4s ease;
        flex: 0 0 320px; 
        h3, h2 {
          margin-bottom: 0px;
        }
        ul.features {
          margin-bottom: 20px;
          li {
            width: 100%; font-size: 12px;
          }
        }
        &:hover {
          width: 360px; flex: 0 0 360px;
          background: linear-gradient(180deg, #8F00FF 0%, #001D59 100%);
        }
      }
    }
  }

  #testimonials {
    margin-bottom: 100px;  width: 100%;
    .reviews {
      width: 100%; display: grid; margin-bottom: 20px;
      grid-gap: 20px; grid-template-columns: repeat(3, auto);
      margin-top: 10px;
      .review {
        padding: 10px; 
        .rev-header {
          width: 100%;  display: flex; flex-direction: row;
          grid-gap: 15px; align-items: center; margin-bottom: 10px;
          .rev-avatar {
            width: 50px; height: 50px; border-radius: 100%; background-size: cover;
            background-color: #EDE8E3; flex: 0 0 50px; background-repeat: no-repeat;
            background-position: center;
          }
          .rev-name {
            width: 100%; 
            .name {
              font-weight: 500; font-size: 16px;
            }
            .stars {
              width: 99px; height: 16px;
              background: url('./images/rating.svg') center center no-repeat;
              background-size: contain
            }
          }
        }
        .review-text {
          font-size: 13px; line-height: 1.3em;
        }
      }
    }
  }

  #faq {
    width: 100%;
    .faq-items {
      .faq-item {
        cursor: pointer; margin-bottom: 10px; width: 100%;
        h4 {
          margin: 0; padding: 10px; background-color: #12132D; border: none; 
          -webkit-user-select: none; -moz-user-select: none; user-select: none; 
          line-height: 1.8em; 
          padding-top: 12px; padding-left: 20px;
          background: #12132D url('./images/faq-open.svg') right 20px center no-repeat;
          background-size: 20px; padding-right: 40px;
          //font-weight: 400; font-style: italic; font-size: 15px;
        }
        .description { 
          display: none; padding: 10px; border-top: none;
        }
        &.open {
          margin-bottom: 0px;
          h4 {
            background-color: #12132D; 
            background: #12132D url('./images/faq-closed.svg') right 20px center no-repeat;
            background-size: 20px;
          }
          .description {
            display: block; border: none;
            background: none; border-bottom: 10px solid #383a58;
            background: #383a58; padding: 10px 20px 0px 20px;
            // background: #ffffff63; font-size: 13px; 
          }
        }
      }
    }
  }
  #contact {
    width: 100%;
  }
}
