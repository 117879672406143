// Define common variables
$primary-color: #1349bb;
$secondary-color: rgba(0, 0, 0, 0.09);
$fade-duration: 0.4s;
$rounded-corner: 8px;
$close-button-size: 24px;

.main-popup {
  .popup-outer {
    position: relative; // Ensure relative positioning for child elements like the close button
    .popup-inner {
      position: relative; // Context for inner content
      animation: fadeIn $fade-duration ease-in;

      #drill-popup {
        .main-input {
          margin-bottom: 0;
        }

        .upload-file-container {
          display: block;
          width: 100%;
        }

        .auto-drop {
          margin-bottom: 15px;
          padding-top: 20px;
          width: 100%;
        }

        input[type="file"] {
          margin: 0;
          width: 100%;
          margin-bottom: 15px;
        }

        .cat-box {
          padding: 20px 20px 10px 20px;
          border-radius: 0 $rounded-corner $rounded-corner $rounded-corner;
        }

        .tabs {
          display: flex;
          width: 100%;
          margin-top: 10px;

          .tab {
            padding: 5px 20px;
            background: $secondary-color;
            border-radius: $rounded-corner $rounded-corner 0 0;
            margin-right: 5px;
            font-weight: 500;
            font-size: 11px;
            cursor: pointer;

            &.active {
              background: $primary-color;
              border: 1px solid rgba(0, 72, 224, 0.22);
            }
          }
        }
      }
    }

    // X Button Styling
    .btn-close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: $close-button-size;
      height: $close-button-size;
      background: $secondary-color;
      color: white;
      font-size: 18px;
      font-weight: bold;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background $fade-duration ease;

      &:hover {
        background: $primary-color;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 14px;
        height: 2px;
        background: white;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

// Fade animation for the popup
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

// Responsive Design
@media (max-width: 768px) {
  .main-popup {
    .popup-outer {
      .popup-inner {
        #drill-popup {
          .cat-box {
            padding: 10px;
          }

          .tabs {
            flex-direction: column;

            .tab {
              margin-bottom: 10px;
            }
          }
        }
      }

      .btn-close {
        top: 5px;
        right: 5px;
        width: 20px;
        height: 20px;
      }
    }
  }
}