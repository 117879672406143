#mobile-version {
  display: none;
}

@media screen and (max-width: 1300px) {
  .dashboard-first, #admin-wrapper main .box-wrapper {
    flex-direction: column;
  }
  #admin-wrapper main .box.home-goals {
    width: 100%;
  }

}

@media screen and (max-width: 980px) {
  #admin-wrapper main .box.activity,
  #admin-wrapper main .box.calendar {
    display: none;
  }
  #web-wrapper {
    flex-direction: column;
  }
  #web-wrapper #main, #web-wrapper #content {
    padding: 100px;
  }
  #web-wrapper #main .main-content.max-width {
    max-width: 100%;
  }
  #web-wrapper #content form {
    width: 100%;
  }
  #home-wrapper #home {
    grid-gap: 15px;
  }

  #home-wrapper header .logo {
    background: url('../images/logo-mobile.svg'); flex: 0 0 auto;
    background-repeat: no-repeat; margin-right: 10px; flex: 0 0 auto; 
    width: 90px; max-width: 100%; height: 90px; margin-bottom: 0px;
  }
  #home-wrapper #practice .timer-wrapper {
    padding-top: 0px;
  }
  #home-wrapper #home .half .timer {
    margin-bottom: 0px;
  }
}


@media screen and (max-width: 780px) {
  #admin-wrapper main .hide-tablet,
  #admin-wrapper main .box.graph {
    display: none;
  }
  #admin-wrapper .background-cover {
    right: 0px; width: 100%; height: 220px;
    &::after {
    background: linear-gradient(180deg, rgba(15, 19, 43, 0) 0%, #0F132B 55.83%);
    left: 0px; width: 100%; height: 100%;
    }
  }
  #admin-wrapper header ul.main-items {
    width: 100%; flex-direction: row; padding: 20px;
  }
  #admin-wrapper main {
    padding-left: 20px; padding-top: 90px;
    padding-right: 20px;
  }
  #admin-wrapper header ul.main-items li:nth-child(n+2):nth-child(-n+2) {
    margin-left: auto;
  }
  #admin-wrapper header {
    top: 0px; width: 100%; height: 75px;
    padding-top: 5px;
  }
  #admin-wrapper main .box.drills-page {
    flex-direction: column;
  }
  #practice .box-wrapper {
    left: 20px;
  }
  #practice .timer-wrapper .drill img {
    max-width: 100%;
  }
  #web-wrapper #main, #web-wrapper #content {
    padding: 40px;
  }
  h1 {
    font-size: 46px; margin-bottom: 20px;
  }
  h2 {
    font-size: 38px;
  }
  h3 {
    font-size: 22px; margin-bottom: 5px;
  }
  #admin-wrapper header.with-background {
    background: linear-gradient(360deg, rgba(15, 19, 43, 0) 0%, #0F132B 100%);
    top: 0px;
    height: 120px;
  }

  #home-wrapper {
    font-size: 14px; line-height: 1.5em;
  }
  #home-wrapper #home h2 {
    font-size: 36px;
  }
  #home-wrapper #features .feature-list {
    display: flex; flex-direction: column; grid-gap: 20px;
  }
  #home-wrapper #home {
    flex-direction: column;
  }
  #home-wrapper #features .feature-list .feature {
    padding: 20px; display: flex; grid-gap: 20px; 
    align-items: center;
  }

  #home-wrapper #features .feature-list .feature .f-image {
    width: 100%; max-width: 150px; grid-gap: 20px;
  }

  #home-wrapper #testimonials .reviews {
    display: flex; flex-direction: column;
  }
  #home-wrapper header {
    height: 100px;
  }
  #home-wrapper header .logo {
    background-size: 50px auto; width: 50px; height: 55px;
  }

  #home-wrapper #plans .subscriptions .sub {
    flex: 1 1 0px; width: auto;
    &:hover {
      flex: 1 1 0px; width: auto;
    }
  }
}

@media screen and (max-width: 620px) {
  #admin-wrapper main .hide-mobile {
    display: none;
  }
  #web-wrapper #main .main-content .logo {
    width: 141px; height: 54px;
  }
  h1 {
    font-size: 36px; margin-bottom: 10px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 16px; margin-bottom: 5px;
  }
  table tr, table tr td {
    height: 35px; 
  }
  table {
    border-collapse: collapse;
  }
  table tr td {
    box-sizing: border-box; 
    display: initial;
  }
  body {
    font-size: 13px;
  }
  #web-wrapper #main, #web-wrapper #content {
    padding: 30px 20px;
  }
  .btn-black, .btn-main {
    font-size: 14px; padding: 14px 20px;
  }
  #web-wrapper #main .flex-grid {
    grid-gap: 10px; flex-direction: column;
  }
  #web-wrapper #content {
    min-width: auto;
  }
  #admin-wrapper main .box.home-goals {
    padding: 20px;
  }
  #home-wrapper header menu {
    display: none;
  }
  #home-wrapper header .nav-toggle {
    display: block;
  }
  #home-wrapper #practice .timer-wrapper .timer .timer-text h1 {
    height: 30px;
  }
  #home-wrapper #features .feature-list .feature {
    flex-direction: column;
  }
  #home-wrapper #features .feature-list .feature .f-image {
    max-width: 300px;
  }
  #home-wrapper #plans .subscriptions {
    width: 100%;
  }
  #home-wrapper #plans .subscriptions {
    flex-direction: column;
  }
  #home-wrapper header .menu-wrapper a.btn-signup {
    margin-right: 45px;
  }
  // #home-wrapper header .logo {
  //   margin-left: 15px; margin-top: 15px;
  // }
  #home-wrapper header.active  {
    position: relative; z-index: 50000;
    .menu-wrapper {
      position: fixed; top: 0; right: 0; bottom: 0; left: 0; 
      background: #041246; z-index: 5000000000000;
      display: flex; flex-direction: column;
    }
    menu {
      display: flex; flex-direction: column; font-size: 21px;
      font-weight: 500;
    }
    .logo {
      background-image: url('../images/logo.svg'); width: 284px; height: 103px; 
      background-size: contain; background-repeat: no-repeat; 
      float: left; margin-right: 15px; margin-top: 20px; margin-bottom: 50px; 
      background-position: center;
    }
    a.btn-signup {
      width: 100%; height: auto; flex: auto; position: fixed; 
      bottom: 0px; left: 0; right: 0; margin-right: 0; border-radius: 0;
    }
    .menu-wrapper a.btn-signin {
      position: fixed; bottom: 49px; width: 100%; height: 40px; 
      background: #223474; margin-right: 0; text-align: center; 
      padding-top: 9px;
    }

  }
  #home-wrapper #plans .subscriptions .sub {
    width: 100%;
    &:hover {
      width: 100%;
    }
  }
  #home-wrapper #home .half .timer {
    padding: 30px 20px 10px 20px;
  }
  #home-wrapper #faq .faq-items .faq-item h4 {
    font-size: 13px;
  }
  
}