.line-chart {
	float: left; width: 100%; display: flex; flex-direction: column;
	height: 100%; height: 280px;
	
	h2 {
		font-weight: 500; font-size: 24px;
	}
	.chart-inner {
		width: 100%; height: 100%; display: flex;
		justify-content: space-around; margin-top: 20px;
		position: relative; grid-gap: 10px;
		.chart-lines {
			position: absolute; top: 10px; left: 0px;
			bottom: 40px; right: 0px;
			div { 
				height: 1px; position: absolute; left: 0px; 
				right: 0px; background: #f6f3ff26;
				.hide {
					opacity: 0;
				}
				&.chart-goal {
					position: absolute; left: 0; right: 0; height: 1px; 
					background: #ffd029; margin-bottom: 15px; z-index: 5000;
				}
			}
			div:nth-child(1n){ bottom: 0%; }
			div:nth-child(2n){ bottom: 20%; }
			div:nth-child(3n){ bottom: 40%; }
			div:nth-child(4n){ bottom: 60%; }
			div:nth-child(5n){ bottom: 80%; }
			div:nth-child(6n){ bottom: 100%; }
		}
		.chart-item {
			position: relative; width: 100%;
			display: flex; flex-direction: column;
			height: 100%; padding: 0px; border-radius: 6px;
			&.active {
				background: rgba(108, 93, 211, 0.1);
			}
			.item-label {
				color: #b3b3b3; font-size: 12px;
				flex-basis: 30px; padding-top: 0px;
				text-align: center; width: 100%; text-align: center;
				text-overflow: ellipsis;
			}
			.item-sublabel {
				color: #FFF; font-size: 12px;
				flex-basis: 30px; padding-top: 10px;
				text-align: center; width: 100%; text-align: center;
				text-overflow: ellipsis;
			}
			.item-label-image {
				width: 100%; padding-top: 100%; border-radius: 100%; 
				background-color: #F1F1F5; margin-top: 5px; margin-bottom: -5px;
				background-size: cover; background-repeat: no-repeat;
				background-position: center; min-width: 30px;
			}
			.item-line-outer {
				height: 100%; 
				.lines-wrapper {
					width: 100%; height: 100%; display: flex;
					flex-direction: row; justify-content: center;
					.bar {
						transition: all 0.5s ease; -webkit-transition: all 0.5s ease;
						width: 9px; height: 200px; border-radius: 9px; min-height: 5px;
						align-self: flex-end; width: 100%;
						margin: 0 1px; position: relative;
						background: linear-gradient(180deg, #8F00FF 0%, rgba(194, 116, 255, 0) 100%);
						&.star::before {
							content: ''; position: absolute; width: 20px; height: 20px; 
							background: url('./images/star.svg') center center no-repeat;
							top: -10px; left: calc(50% - 10px);
						}
						.bar-value {
							padding: 0px; position: absolute; width: 100%;
							font-size: 10px; left: 0px; top: -30px; text-align: center;
							text-align: center; border-radius: 24px;
						}
					}
				}
			}
		}
	}
}
