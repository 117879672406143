.about-page {
  background: rgba($color: #000000, $alpha: 0.6); /* A dark background */
  border-radius: 18px;
  color: #fff; /* Light text color for contrast */
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-footer {
    margin-top: auto;
  }

  h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #5885f7;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    max-width: 800px;
    text-align: center;
    margin-bottom: 40px;
  }

  .footer {
    margin-top: 50px;
    background-color: #12132D;
    padding: 20px 0;
    width: 100%;
    text-align: center;
    color: #aaa;
    font-size: 0.8rem;
  }

  a {
    color: #5885f7;
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s ease;
  
    &:hover {
      color: #f1f1f1;
    }
  }
}