#web-wrapper {
  display: flex; height: 100vh;
}
#web-wrapper, #home-wrapper  {
  // display: flex; height: 100vh;
  #main {
    flex: 0 0 1; width: 100%;
    background: url('../files/background.jpg') center center no-repeat;
    background-size: cover; display: flex; align-items: center;
    justify-content: center;
    
    .flex-grid {
      display: flex; grid-gap: 20px;
    }
  }
  #subscribe {
    flex: 0 0 1; width: 100%;  align-items: center;
    justify-content: center; display: flex; margin-top: 80px;

    h2 {
      text-align: center;
      color: #ffd27d;
    }
  }
  .main-content {
    text-align: left;
    .logo {
      width: 283px; height: 108px;
      background: url('../images/logo.svg');
      background-size: contain; background-repeat: no-repeat;
      margin-bottom: 20px;
    }
    &.max-width {
      max-width: 500px; width: 100%;
    }
    .subscriptions {
      display: flex; grid-gap: 30px; align-items: center;
      .sub {
        display: flex; flex-direction: column; justify-content: center; 
        align-items: center; padding: 50px 20px;
        background: #001D59; box-shadow: 0px 46px 29.1px rgba(0, 0, 0, 0.5); 
        border-radius: 24px; width: 320px; text-align: center;
        -webkit-transition: all 0.4s ease; transition: all 0.4s ease;
        flex: 0 0 320px;
        height: 28em;
        padding: 5em 2em;
        place-content: space-between;
        transition: all 0.4s ease;
        h1, h3, h2 {
          margin-bottom: 0px;
          text-align: center !important;
        }
        ul.features {
          margin-bottom: 20px;
          li {
            float: left; width: 100%; font-size: 12px;
          }
        }
        &:hover {
          //width: 360px; flex: 0 0 360px;
          transform: scale(1.05); /* Smooth hover effect */
          background: linear-gradient(180deg, #8F00FF 0%, #001D59 100%);
          // padding: 70px 20px;.
        }
      }
      .practice-wrap {

      }
    }
  }
  #content {
    background: #001D59; padding: 20px 50px; text-align: left;
    min-width: 500px; display: flex; align-items: flex-start; 
    flex-direction: column; justify-content: center;
  }
}

#admin-wrapper {
  width: 100%; float: left; min-height: 100vh; background: rgba(15, 19, 43, 1);
  .background-cover {
    position: fixed; left: 0px; top: 0px; bottom: 0px; width: 220px;
    background: url('../files/background.jpg') center center no-repeat;
    background-size: cover;
    &::after {
      content: '';
      width: 120px; position: absolute; right: 0px; top: 0px; bottom: 0px;
      background: linear-gradient(90deg, rgba(15, 19, 43, 0) 0%, #0F132B 55.83%);
    }
  }
  header {
    width: 110px; position: fixed; left: 0px; top: 0px; 
    bottom: 0px; z-index: 5000;
    ul.main-items {
      width: 40px; margin-left: auto; display: flex;
      grid-gap: 15px; flex-direction: column;
      padding-top: 40px;
      li {
        line-height: 1000%; flex: 0 0 40px; 
        a {
          float: left; width: 40px; height: 40px; background-color: #001D59;
          border-radius: 100%; overflow: hidden; background-position: center;
          line-height: 1000%; flex: 0 0 40px; background-repeat: no-repeat;
          -webkit-transition: all 0.4s ease; transition: all 0.4s ease;
          &.btn-home {
            background: transparent; position: relative;
            overflow: visible;
            &:before {
              content: ''; width: 59px; height: 57px; position: absolute;
              background: url('../images/ico-home.svg') center center no-repeat;
              background-size: contain;
              bottom: 0px; left: -8px;
            }
            &.active {
              background-color: transparent;
            }
          }
          &.btn-goals {
            background-image: url('../images/ico-goals.svg');
          }
          &.btn-history {
            background-image: url('../images/ico-history.svg');
          }
          &.btn-drills {
            background-image: url('../images/ico-drills.svg');
          }
          &.btn-admin {
            background-image: url('../images/ico-admin.svg');
          }
          &.btn-logout {
            background-image: url('../images/ico-logout.svg');
          }
          &:hover {
            transform: scale(1.1);
          }
          &.active {
            background-color: #8F00FF;
          }
        }
      }
    }
  }
  main {
    padding-left: 150px; float: left; width: 100%; 
    position: relative; padding-top: 40px; padding-right: 40px;
    .box {
      display: flex; flex-direction: column; align-items: flex-start; 
      padding: 20px; background: linear-gradient(90deg, #14236F 0%, #141836 100%); 
      border-radius: 8px; width: 100%; 
      .box-search {
        display: flex; grid-gap: 20px; white-space: nowrap; margin-bottom: 25px; 
        width: 100%;
      }
      .box-header {
        display: flex; font-family: 'Poppins'; font-style: normal; 
        font-weight: 400; font-size: 14px; line-height: 28px;
        width: 100%; margin-bottom: 15px;
        .title {
          width: 100%; color: #FFF;
        }
        .action {
          margin-left: auto; color: #FFF; font-size: 12px;
          white-space: nowrap;
          a {
            color: #FFF; font-style: italic;
          }
          &.a-link {
            text-decoration: underline;
          }
        }
        .tab {
          font-size: 16px; padding: 10px 20px;
          cursor: pointer;
          &.active {
            border-bottom: 4px solid #0052FF;
          }
        }
      }
      &.graph {
        max-width: 300px;
        .pie-details {
          position: absolute; left: 0; right: 0; height: 260px; display: flex; 
          flex-direction: column; align-items: center; justify-content: center;
          z-index: 8000;
          .done-todo {
            .done {
              font-size: 48px; font-weight: 600;
            }
            .todo {
              font-size: 16px;
            }
          }
          .subline {
            margin-bottom: 10px;
          }
          .btn-main {
            margin-bottom: 0px;
          }
        }
      }
      &.calendar {
        max-width: 360px;
      }
      &.drills {
        .drill-wrapper {
          margin-top: 20px;
        }
        .drill-details {
          float: left; width: 100%; margin-top: 20px;
          .drill-text {
            float: left; width: auto;
          }
          .btn-main {
            float: right; width: auto;
          }
        }
        img {
          width: 100%; height: auto; max-width: 550px;
        }
      }
      &.recent {
        min-width: 260px;
        .drills-recent {
          display: flex; flex-direction: column;
          margin-top: 15px; 
          li {
            display: flex; align-items: center; cursor: pointer;
            margin-bottom: 5px;
            background: url('../images/ico-details.svg') center right 5px no-repeat;
            padding: 5px; border-radius: 6px;
            &:hover {
              background-color: #ffffff17; 
            }
          }
          i {
            font-weight: 600; font-size: 10px;
            font-style: normal; background: #8F00FF;
            border-radius: 12px; width: 30px; text-align: center;
            margin-right: 15px;
          }
        }
        .no-drills {
          display: flex; flex-direction: column; width: 100%;
          text-align: center; align-items: center; justify-content: center;
          .icon {
            width: 80px; height: 80px; margin-top: 30px;
            background: url('../images/metronome.svg') center center no-repeat;
            background-size: contain; margin-bottom: 15px;
          }
          span {
            font-size: 12px; font-style: italic; margin-bottom: 15px;
          }
        }
      }
      &.home-goals {
        width: 520px; padding: 30px;
      }
      &.home-drills {
        padding: 30px;
      }
      &.drills-page {
        padding: 40px; display: flex;
        grid-gap: 20px; flex-direction: row;
        .half {
          flex-grow: 1; flex-basis: 0;
        }
      }
      .drill-list {
        width: 100%; float: left;
        img {
          max-width: 570px; width: 100%;
        }
        .drill {
          width: 100%; float: left; margin-bottom: 25px;
          .drill-header {
            margin-bottom: 15px; width: 100%; float: left;
            .label {
              font-weight: 600; font-size: 10px;
              font-style: normal; background: #0052FF;
              border-radius: 12px; text-align: center;
              margin-right: 15px; padding: 5px 10px;
            }
            .name {
              background: url('../images/ico-details-white.svg') center right no-repeat;
              padding-right: 30px; font-size: 13px; margin-right: 15px;
            }
            .info {
              float: left; width: 100%; font-size: 12px;
              span {
                margin-right: 20px;
              }
            }
          }
        }
      }
      .box-content {
        width: 100%; float: left; position: relative;
        .graph-example {
          width: 100%; padding-top: 100%; background: blue;
          border-radius: 100%;
        }
        .calendar-example {
          width: 100%; padding-top: 100%; background: green;
        }
        .users-content {
          .btn-main {
            width: auto; float: right;
            &.full-width {
              width: 100%;
            }
          }
          .search-add {
            display: flex; grid-gap: 20px; white-space: nowrap;
            margin-bottom: 25px;
            .btn-main {
              margin-bottom: 0; display: flex; align-items: center;
            }
          }
        }
      }
    }
    .box-wrapper {
      display: flex; grid-gap: 20px; width: 100%;
      margin-bottom: 20px;
    }
    .box-insection {
      display: flex; grid-gap: 20px; width: 100%;
    }
  }
}