#gallery-wrapper {
    position: fixed; top: 0; left: 0; right: 0; 
    bottom: 0; z-index: 99999999999; display: none;
    &.active {
        display: block;
    }
    .gallery-black-bg {
        position: fixed; top: 0; left: 0; right: 0; bottom: 0; 
        background: #404040cc; display: flex; align-items: center; 
        justify-content: center;
    }
    .gallery-image {
        position: absolute; top: 20px; left: 20px; right: 20px; bottom: 20px; 
        background-size: contain; background-repeat: no-repeat; 
        background-position: center;
    }
    .btn-close {
      position: absolute; top: 30px; right: 30px; width: 36px; 
      height: 36px; cursor: pointer; border-radius: 100%; 
      background: url("./images/ico-close.svg") center center no-repeat;
      z-index: 50000;
    }
    .btn-prev {
        width: 30px; height: 30px; cursor: pointer;  position: absolute;
        background: url('./images/btn-prev.svg') center center no-repeat;
        left: 20px; top: calc(50% - 15px); z-index: 50000;
    }
    .btn-next {
        width: 30px; height: 30px; cursor: pointer; position: absolute;
        background: url('./images/btn-next.svg') center center no-repeat;
        right: 20px; bottom: calc(50% - 15px); z-index: 50000; top: auto;
    }
}