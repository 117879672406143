.c-calendar {
  border-radius: 16px; grid-area: 1 /1 / 3 / 2; width: 100%;
  margin-bottom: 20px;
  .c-cal-header {
    display: flex; align-items: center; justify-content: space-between; 
    margin-top: 20px;
    .c-cal-title {
      font-weight: bold; 
      font-size: 14px; margin-top: 10px; width: 125px;
    }
    .c-cal-monthyear {
      display: flex;  align-items: center; width: 100%;
      .c-cal-selected-month {
        font-family: 'Inter'; font-style: normal; margin: 0 5px;
        font-weight: 400; font-size: 14px; line-height: 28px;
        text-align: center;
      }
      .c-cal-left, .c-cal-right {
        cursor: pointer;
      }
    }
  }
  .c-calendar-full {
    display: flex; flex-flow: column;
    .c-calender-weekdays {
      margin-top: 15px
    }
    .c-calendar-week {
      display: grid; grid-template-columns: repeat(7, 1fr);
      .c-calendar-day, .c-calendar-weekday, .c-calendar-day-othermonth {
        margin: 9px; text-align: center;
        font-weight: 300; font-size: 16px;
      }
      .c-calendar-day-selected {
        text-align: center; font-weight: 700; 
        font-size: 12px; display: flex; justify-content: center;
        .c-calendar-selected {
          background-color: #8F00FF; color: #FFF; 
          border-radius: 100%; width: 32px; align-self: center; justify-self: center; 
          height: 32px; display: flex; align-items: center; justify-content: center; 
          font-weight: 700; font-size: 13px; cursor: pointer;
          .today {
            color: #FFF;
          }
        }
      }
      .c-calendar-day {
        color: #FFF; cursor: pointer; position: relative;
        &.marked::before {
          content: ''; width: 8px; height: 8px; border-radius: 100%;
          background-color: #AD46FF; position: absolute; bottom: -10px;
        }
        &.star::before {
          content: ''; width: 15px; height: 15px; border-radius: 100%;
          position: absolute; bottom: -10px;
          background: url('./images/star.svg') center center no-repeat;
          background-size: contain;
        }
      }
      .today {
        color: gray;
      }
      .c-calendar-day-othermonth {
        color: #B1B5C3; cursor: pointer;
      }
      .c-calendar-weekday {
        color: #FFF; font-weight: 500;
      }
      .c-calender-today {
        background-color: red; color: #FFF; 
        border-radius: 30px; width: 32px; align-self: center; justify-self: center; 
        height: 32px; display: flex; align-items: center; justify-content: center; 
        font-weight: 700; font-size: 13px;
      }
    }
  }
}


.c-calendar-day.star {
  background-color: transparent; // Gold background for starred days
  font-weight: bold; // Bold text for starred days
}