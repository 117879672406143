#home {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .box-wrapper {
    display: flex;
    grid-gap: 20px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: flex-start;
    width: auto;
    max-width: 1000px;
  }
}

#practice,
#web-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;

  .timer-wrapper {
    max-width: 800px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    padding-top: 30px;

    h1, h2 {
      color: #ffd27d;
      margin: 25px, auto;
    }

    &.subscription {
      background: #001d59;
      padding: 50px;
      padding-bottom: 0;
      border-radius: 24px;

      .timer {
        margin-bottom: 10px;
      }
    }

    &::before {
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(0, 82, 255, 0.8) 0%,
        rgba(15, 19, 43, 0) 100%
      );
      content: '';
      width: 450px;
      height: 450px;
      position: absolute;
      top: -60px;
      background: transparent;
    }

    .timer {
      max-width: 450px;
      width: 100%;
      position: relative;
      z-index: 500;
      margin-bottom: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #041246;
      padding: 40px;
      border-radius: 15px;

      .info-goal {
        font-weight: 400;
        color: #fff;
        margin-top: -5px;
        margin-bottom: 15px;
        font-style: italic;
        font-size: 14px;
      }

      .info-congrats {
        color: #ffd27d;
        font-weight: 500;
        margin-bottom: 20px;
      }

      .info-drill {
        font-style: normal; /* Remove italic from the title */
        font-size: 16px;
        width: 100%;
        text-align: center; /* Align center for the titles and values */
        margin-bottom: 10px;
        margin-top: 5px;
        font-weight: 500;
        border: solid, 1px, #0052FF;
        padding: 5px;
        border-radius: 4px;

      
        /* Set the layout for titles and values */
        display: flex;
        justify-content: space-around; /* Spread the items evenly */
        flex-wrap: wrap; /* Ensure responsiveness */
        align-items: flex-start;
        gap: 10px; /* Add spacing between info-items */
      
        .info-item {
          display: flex;
          flex-direction: column;
          align-items: center; /* Align items to center */
          gap: 5px; /* Add some space between title and value */
      
          .title {
            font-weight: 600; /* Make the title bold */
            font-size: 14px; /* Slightly smaller font size */
          }
      
          .value {
            font-style: italic;
            font-size: 12px; /* Smaller font for values */
            color: #aaa; /* Default lighter color for values */
          }
      
          &.highlight {
            .value {
              color: #ffd27d; /* Yellow color for time and date */
            }
          }
        }
      }

      .timer-box {
        float: left;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
        width: 100%;
      }

      .metronome-box {
        margin-bottom: 15px;
        width: 100%;

        h3 {
          width: 100%;
          text-align: center;
        }

        .drill-options {
          display: flex;
          grid-gap: 10px;
        }
      }

      .btn-savelog {
        background: transparent;
        border: 1px solid #0052fe;
      }

      .box-savelog {
        text-align: left;
        width: 100%;
      }

      .timer-slider {
        float: left;
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        grid-gap: 10px;
        align-items: center;
        position: relative;
        z-index: 90000;

        .in-value {
          width: 80px;
          flex: 0 0 80px;
        }
      }

      .timer-text {
        text-align: left;
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        justify-content: center;

        h1 {
          margin-bottom: 0px;
          height: 60px;
          margin-right: 10px;
          width: 250px;
          text-align: center;
          margin-left: 10px;
        }

        .btn-reset {
          width: 40px;
          height: 40px;
          border-radius: 12px;
          border: none;
          cursor: pointer;
          background: #002b85 url('../images/ico-reset.svg') center center no-repeat;
        }

        .btn-play,
        .btn-stop,
        .btn-pause {
          gap: 4px;
          width: 40px;
          height: 40px;
          border: 1px solid #0052ff;
          border-radius: 8px;
          flex: 0 0 40px;
          background: url('../images/ico-play.svg') center center no-repeat;
          cursor: pointer;
          transition: all 0.4s ease;

          &:hover {
            background-color: #000;
          }
        }

        .btn-stop {
          background: url('../images/ico-stop.svg') center center no-repeat;
        }

        .btn-pause {
          background: url('../images/ico-pause.svg') center center no-repeat;
        }
      }

      .btn-main {
        float: left;
        font-weight: 400;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        transition: all 0.4s ease;
        align-content: center;
        align-items: center;

        &.big {
          font-size: 20px;
          padding: 20px 0;
        }

        &.stop {
          background-color: #ff4a4a;

          i {
            display: inline-flex;
            margin-right: 10px;
            width: 20px;
            height: 20px;
            background: url('../images/ico-stop.svg') center center no-repeat;
          }
        }

        &.play {
          i {
            display: inline-flex;
            width: 20px;
            height: 20px;
            display: inline-flex;
            margin-right: 5px;
            background: url('../images/ico-play.svg') center center no-repeat;
          }
        }
      }

      .select-drill-wrapper {
        float: left;
        width: 100%;
        margin-bottom: 15px;
      }

      .log-search {
        display: flex;
        grid-gap: 15px;
        width: 100%;
        float: left;
        height: auto;
        flex-direction: row;
        align-items: center;
        margin-top: 15px;

        input {
          border: none;
          margin-bottom: 0px;
        }

        .btn-main {
          margin-bottom: 0px;
          width: 120px;
          flex: 0 0 120px;
        }
      }
    }

    .drill {
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 500;

      .drill-wrapper {
        margin-bottom: 5px;

        img {
          max-width: 100%;
          border: 2px solid #0048e0;
          border-radius: 6px;
        }
      }
    }

    button {
      overflow: hidden;
      line-height: 100000px;
    }
  }

  .btn-history {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    float: right;
    background: #001d59 url('../images/ico-history.svg') center center no-repeat;
    cursor: pointer;
  }

  .box-wrapper {
    position: fixed;
    bottom: 20px;
    right: 20px;
    left: 220px;
    width: auto !important;
    z-index: 5000;
    text-align: left;

    .box-header {
      text-align: left;
    }

    .ico-info {
      padding: 10px 30px 10px 45px;
      float: left;
      display: flex;
      align-items: center;
      background: #002b85 url('../images/ico-info.svg') left 10px center no-repeat;
      border-radius: 6px;
    }
  }
}

.popup-inner .timer-box h3 {
  display: none;
}