.main-popup {
	position: fixed; top: 0px; left: 0px;
	right: 0px; bottom: 0px; z-index: 9999999;
	display: flex; align-items: center; justify-content: center;
	display: none;  
	&.active {
		display: flex;
	}
	.bg-close {
		position: absolute; left: 0px; right: 0px; 
		bottom: 0px; top: 0px; background: #000000d9;
	}
	.popup-outer {
		background: linear-gradient(90deg, #14236F 0%, #141836 100%); border-radius: 16px; padding: 20px;
		z-index: 500; position: relative; width: 100%; position: relative;
		max-width: 620px; max-height: calc(100vh - 40px); text-align: left;
		overflow-y: auto;
		.btn-close {
			position: absolute; top: 20px; right: 20px;
			background: url('images/btn-close.svg') center center no-repeat;
			width: 20px; height: 20px; background-size: 20px 20px;
			cursor: pointer;
		}
		h2 {
			margin-top: 5px; font-size: 24px; font-weight: 400;
		}
		.popup-inner {
			width: 100%; float: left; margin-top: 5px; 
			input[type=text], input[type=number] {
				margin-bottom: 15px;
			}
			.cat-box {
				float: left; width: 100%; padding: 10px; background: #00000017; 
				border-radius: 8px; border: 1px solid #0048e038;
			}
			.loading-wrapper {
				position: absolute; 
				svg {
					margin-top: 15px;
				}
			}
		}
		.popup-actions {
			margin-top: 20px; width: 100%; float: left;
			display: flex; flex-direction: row;
			.btn-cancel {
				padding: 15px; border-radius: 9px; color: #9bb7f1;
    		text-align: center; border: 1px solid #0052fe3d;
				font-weight: 500; font-size: 14px; height: 45px;
				width: 100%; cursor: pointer; float: left; 
				display: flex; align-items: center; margin-bottom: 0px;
				background: transparent;
			}
			.btn-save {
				padding: 15px; border-radius: 9px; color: #FFF; 
				border: none; text-align: center; 
				float: left;  display: flex; align-items: center;
				font-weight: 500; font-size: 14px; height: 45px;
				width: 100% !important; margin-right: 10px; 
				cursor: pointer; margin-bottom: 0px;
			}
		}
	}
}