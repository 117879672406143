.footer {
  display: flex;
  justify-content: center;
  color: white;
  text-align: center;
  padding: 20px 10px;
  width: 100%;
  background: linear-gradient(135deg, #0a0e1a, #141a2d); /* Updated to match the app colors */
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.4);
  margin-top: auto;
  position: relative;
  z-index: 10;

  &-wraper {
    max-width: 1000px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Equal column widths */
    gap: 20px;
  }
}

/* Style for all titles */
.footer-title {
  font-size: 16px;
  color: #007bff; /* Neon blue accent */
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 1px; /* Slight spacing for elegance */
  text-align: left;
}

/* Align all content containers to the left */
.link-container,
.text-container,
.social-container,
.help-container,
.extra-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  img {
    height: 42px;
    margin-bottom: 10px;
  }

  p {
    color: #aaa;
    margin-bottom: 10px;
    font-size: 14px;
  }
}

/* Footer links styling */
.footer-link {
  color: #007bff; /* Neon blue for links */
  text-decoration: none;
  margin: 5px 0;
  font-size: 14px;
  transition: color 0.3s ease, transform 0.3s ease;

  &:hover {
    color: #8a2be2; /* Neon purple hover effect */
    transform: scale(1.1);
  }
}

.social-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    margin-bottom: 15px;
    font-weight: normal;
  }

  a {
    display: inline-block;
    margin: 5px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;

    img {
      filter: invert(1); 
      padding: 5px;
      opacity: 50%;
      margin-left: -22px;
    }

    &:hover {
      transform: scale(1.2);
    }
  }
}

.text-container {
  grid-column: span 1; 
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 10px;
  align-items: center;
  align-items: flex-start;

  img {
    height: 80px;
    margin-bottom: 10px;
  }

  p {
    margin: 0;
    color: #aaa;
    font-size: 12px;

    a {
      margin-left: 5px;
      color: #007bff;
      text-decoration: underline;

      &:hover {
        color: #8a2be2;
      }
    }
  }
}

.extra-container {
  color: white;
  font-size: 14px;

  p {
    font-weight: normal;
    text-align: left;
  }
}

.help-container {
  span {
    margin-top: 10px;
    font-size: 12px;
    color: #ccc;
    text-align: left;
  }
}