#plans {

  .subscriptions {
    display: flex;
    grid-gap: 30px;
    align-items: center;

    .btn-main {

      &:hover {
        background: #003bb3; /* Change color on hover */
        transform: scale(1.1); /* Grow the button slightly */
      }

    }

    &:hover {
      transform: scale(1.05); /* Grow the card slightly */
    }
    
  }
}
