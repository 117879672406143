.tickbox-wrapper {
    margin-bottom: 10px; font-weight: 400; font-size: 12px; float: left; 
    font-family: var(--f-mainfont); width: 100%;
    .tickbox {
        -webkit-appearance: none; appearance: none; display: inline-block; 
        width: 22px; height: 22px; padding: 0px; background-clip: content-box; 
        border: 1.5px solid #ADBBD4; border-radius: 8px; background-color: var(--c-white); 
        margin-left: 0px; margin-right: 0px; cursor: pointer; overflow: hidden;
        position: relative;
    }
    .tickbox input[type=checkbox] {
        visibility: hidden;
    }
    .tickbox-checked {
        width: 22px; height: 22px; background-color: #6837FA; position: absolute;
        background: url('./images/check.svg') center center no-repeat;
        background-size: 10px;
    }
    label {
        margin-bottom: 0px; font-weight: 300;
    }
}
