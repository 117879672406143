.radio-options {
  float: left; width: 100%; margin-bottom: 15px;
  .r-options {
    display: grid; float: left; width: 100%; 
    grid-template-columns: auto auto; grid-gap: 10px;
    .option {
      display: flex; align-items: center; grid-gap: 7px;
      label {
        margin-bottom: 0px; font-size: 13px; color: #d7d7d7;
      }
    }
  }
}