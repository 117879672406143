.donut-chart {
  position: relative;
  float: left;
  
  .svg-border {
    border-radius: 16px;
    overflow: visible; /* Adjust this to visible or remove if not necessary */
  }
  
  .circle_animation {
    animation: css 1s ease-out forwards; /* Animation to smoothly draw the circle */
  }
}

/* This keyframes animation can stay for smooth transition if needed */
@keyframes css {
  to {
    stroke-dashoffset: 0; /* Final position of the stroke */
  }
}



.pie-chart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 260px;
  position: relative;
}

.pie-chart {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: conic-gradient(#ffd029 75%, rgb(0, 57, 176) 266deg); /* Default background */
}

.pie-chart-inner {
  width: 80%;
  height: 80%;
  background: linear-gradient(90deg, #14236F 0%, #141836 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.pie-chart-text {
  font-size: 24px;
  font-weight: bold;
  color: #0039B0;
}
.pie-chart-text {
  display: none;
}