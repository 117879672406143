.plan-comparison {
  text-align: center;
  margin: 40px 0;
  width: 70%;
  margin: 80px auto;

  h1, h2 {
    margin-bottom: 10px;
    color: #ffd27d;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 100%;
    margin: 25px auto;
  }

  h3 {
    width: 100%;
    text-align: center;
  }

  p {
    font-size: 16px;
    color: #ccc;
    margin-bottom: 20px;
  }

  .comparison-table {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    background-color: #001d59;

    .table-header,
    .table-row {
      display: flex;
      padding: 10px 20px;

      .cell {
        flex: 1; /* Ensures all columns have equal width */
        text-align: center;

        &:first-child {
          text-align: left; /* Left-align the first column */
        }
      }
    }

    .table-header {
      background-color: #0052FF;
      font-weight: bold;
      color: #fff;

      .cell {
        padding: 10px 20px; /* Ensures header cells align with rows */
      }
    }

    .table-row {
      &:nth-child(even) {
        background-color: #0f1c4b;
      }

      &:nth-child(odd) {
        background-color: #001d59;
      }

      .cell {
        padding: 10px 20px; /* Matches the header cell padding */
        color: #fff;

        &:first-child {
          text-align: left; /* Left-align the first column */
        }
      }
    }
  }
}