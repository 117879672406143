/*########################################
/#####RESET ELEMENTS
########################################*/
* {
  margin: 0; padding: 0; box-sizing: border-box;
}
ul {
  list-style: none; list-style-type: none;
}
ul li {
  float: left;
}
img, fieldset {
  border: none;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: normal; margin-bottom: 0px;
}
::-webkit-scrollbar {
    width: 0; background: transparent; 
}

@font-face { 
  font-family: "Inter"; 
  font-style: normal; 
  font-weight: 100;
  src: url("font-files/Inter-Thin.woff2") format("woff2"); 
}
@font-face { 
  font-family: "Inter"; 
  font-style: italic; 
  font-weight: 100;
  src: url("font-files/Inter-ThinItalic.woff2") format("woff2"); 
}
@font-face { 
  font-family: "Inter"; 
  font-style: normal; 
  font-weight: 200;
  src: url("font-files/Inter-ExtraLight.woff2") format("woff2"); 
}
@font-face { 
  font-family: "Inter"; 
  font-style: italic; 
  font-weight: 200;
  src: url("font-files/Inter-ExtraLightItalic.woff2") format("woff2"); 
}
@font-face { 
  font-family: "Inter"; 
  font-style: normal; 
  font-weight: 300;
  src: url("font-files/Inter-Light.woff2") format("woff2"); 
}
@font-face { 
  font-family: "Inter"; 
  font-style: italic; 
  font-weight: 300;
  src: url("font-files/Inter-LightItalic.woff2") format("woff2"); 
}
@font-face { 
  font-family: "Inter"; 
  font-style: normal; 
  font-weight: 400;
  src: url("font-files/Inter-Regular.woff2") format("woff2"); 
}
@font-face { 
  font-family: "Inter"; 
  font-style: italic; 
  font-weight: 400;
  src: url("font-files/Inter-Italic.woff2") format("woff2"); 
}
@font-face { 
  font-family: "Inter"; 
  font-style: normal; 
  font-weight: 500;
  src: url("font-files/Inter-Medium.woff2") format("woff2"); 
}
@font-face { 
  font-family: "Inter"; 
  font-style: italic; 
  font-weight: 500;
  src: url("font-files/Inter-MediumItalic.woff2") format("woff2"); 
}
@font-face { 
  font-family: "Inter"; 
  font-style: normal; 
  font-weight: 600;
  src: url("font-files/Inter-SemiBold.woff2") format("woff2"); 
}
@font-face { 
  font-family: "Inter"; 
  font-style: italic; 
  font-weight: 600;
  src: url("font-files/Inter-SemiBoldItalic.woff2") format("woff2"); 
}
@font-face { 
  font-family: "Inter"; 
  font-style: normal; 
  font-weight: 700;
  src: url("font-files/Inter-Bold.woff2") format("woff2"); 
}
@font-face { 
  font-family: "Inter"; 
  font-style: italic; 
  font-weight: 700;
  src: url("font-files/Inter-BoldItalic.woff2") format("woff2"); 
}
@font-face { 
  font-family: "Inter"; 
  font-style: normal; 
  font-weight: 800;
  src: url("font-files/Inter-ExtraBold.woff2") format("woff2"); 
}
@font-face { 
  font-family: "Inter"; 
  font-style: italic; 
  font-weight: 800;
  src: url("font-files/Inter-ExtraBoldItalic.woff2") format("woff2"); 
}
@font-face { 
  font-family: "Inter"; 
  font-style: normal; 
  font-weight: 900;
  src: url("font-files/Inter-Black.woff2") format("woff2"); 
}
@font-face { 
  font-family: "Inter"; 
  font-style: italic; 
  font-weight: 900; 
  src: url("font-files/Inter-BlackItalic.woff2") format("woff2"); 
}
@font-face { 
  font-family: 'Poppins';
  src: url('font-files/poppins-bold-webfont.woff2') format('woff2'),
       url('font-files/poppins-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face { 
  font-family: 'Poppins';
  src: url('font-files/poppins-regular-webfont.woff2') format('woff2'),
       url('font-files/poppins-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face { 
  font-family: 'Poppins';
  src: url('font-files/poppins-semibold-webfont.woff2') format('woff2'),
       url('font-files/poppins-semibold-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/*########################################
/#####MAIN ELEMENTS
########################################*/
html {

}
body {
  font-family: 'Inter'; background-color: #001D59; 
  font-style: normal; font-weight: 300; font-size: 16px; 
  line-height: 24px; color: #D1D5DF;
}
h1 {
  font-family: 'Poppins'; margin-bottom: 35px;
  font-style: normal; font-weight: 500; font-size: 72px; 
  line-height: 110%; letter-spacing: 0.02em; color: #FFFFFF;
}
h2 {
  font-family: 'Poppins'; font-style: normal; font-weight: 500; 
  font-size: 48px; line-height: 100%; color: #FFFFFF;
  margin-bottom: 25px;
  .small {
    font-size: 16px; color: #ffce07;
  }
}
h3 {
  font-family: 'Inter'; font-style: normal; font-weight: 500; font-size: 24px; 
  line-height: 150%; letter-spacing: 0.02em; color: #FFFFFF;
  margin-bottom: 15px;
}
h4 {
  font-family: 'Inter'; font-style: normal; font-weight: 500; font-size: 18px; 
  line-height: 150%; letter-spacing: 0.02em; color: #FFFFFF;
  margin-bottom: 25px;
}
p {
  float: left; margin-bottom: 10px; width: 100%;
}
hr {
  border: none; height: 1px; width: 100%; background: #f2f2f2;
  float: left; margin: 25px 0;
}
label {
  font-family: 'Inter'; font-style: normal; font-weight: 500; 
  font-size: 14px; line-height: 20px; color: #B1B5C3;
  width: 100%; float: left; margin-bottom: 5px;
}
input[type=number], input[type=email], input[type=text], input[type=password] {
  font-family: 'Inter'; font-style: normal; font-weight: 300; 
  font-size: 16px; line-height: 24px; color: #FFFFFF;
  background: transparent; border: 1px solid #0048E0;
  border-radius: 8px; padding: 8px 15px; width: 100%;
  float: left;
  &.in-search {
    padding-left: 40px; 
    background: url('../images/ico-search.svg') left 10px center no-repeat;
  }
  &[disabled] {
    opacity: 0.5;
  }
}
input[type=file] {
  padding: 10px; border: 1px solid #0048E0; border-radius: 8px;
}
form {
  float: left; width: 100%;
}
.label-trial {
  position: fixed; z-index: 9000; background: #d43131; right: 20px; 
  padding: 2px 10px; font-size: 13px; color: #FFF; transform: rotate(45deg);
  width: 163px; right: -40px; top: 20px; text-align: center; font-weight: 599; 
  padding: 5px 0; box-shadow: #0000005e 0px 4px; color: #fff200;
}
.error-msg {
  font-family: "Inter"; font-size: 12px; font-weight: 400; font-style: italic; 
  color: #ffc945; line-height: 1.5em;
}
.description {
  float: left; width: 100%; margin-bottom: 20px;
}
.btn-black {
  font-family: 'Inter'; font-size: 18px; color: #FFF;
  background: #0F132B; padding: 20px 40px; font-weight: 400;
  text-decoration: none; border-radius: 200px;
}
.btn-logout-main {
  position: fixed; right: 20px; top: 20px; color: #FFF;
  font-size: 13px;
}
.btn-main {
  background: #0052FF; color: #FFF; border: none;
  font-family: 'Inter'; font-size: 16px; border-radius: 8px;
  padding: 15px 20px; font-weight: 400; width: 100%;
  margin-bottom: 15px; text-decoration: none;
  cursor: pointer;
  &.transparent {
    background: transparent;
  }
  &.center {
    text-align: center;
  }
  &.small {
    font-size: 14px; padding: 8px 20px;
  }
  &.no-width {
    width: auto;
  }
  &.light {
    background: #0052ff4d;
  }
}
.or-line {
  width: 100%; height: 1px; background: #3878FF;
  position: relative; margin: 15px 0 25px 0;
  &::before {
    content: attr(data-value); position: absolute; white-space: pre; 
    display: inline; top: 12px; left: 50%; transform: translate(-50%, -25px); 
    background: #131f53; padding: 0 5px; color: #FFF; font-family: 'Inter'; font-size: 13px;
  }
}
table {
  width: 100%; font-size: 14px; 
  tr {
    th {
      text-align: left; font-size: 14px; font-weight: 600;
      border-bottom: 1px solid #0039B0; padding: 5px;
      white-space: nowrap;
      &[align=center] {
        text-align: center;
      }
    }
    td {
      padding: 5px;
      a {
        color: #FFF;
      }
    }
    .note {
      max-width: 100px; overflow: hidden; white-space: nowrap; 
      text-overflow: ellipsis; float: left; font-size: 12px; 
      font-style: italic;
    }
    .btn-details {
      background: url('../images/ico-details-white.svg') center center no-repeat;
      width: 20px; height: 20px; overflow: hidden; float: left;
      line-height: 10000%; margin-right: 20px;
    }
    .btn-delete {
      background: url('../images/ico-delete.svg') center center no-repeat;
      width: 20px; height: 20px; overflow: hidden; float: left;
      line-height: 10000%;
    }
    .btn-edit {
      background: url('../images/ico-edit.svg') center center no-repeat;
      width: 20px; height: 20px; overflow: hidden; float: left;
      line-height: 10000%; border: none;
    }
    
    .ico-checked {
      background: url('../images/ico-checked.svg') center center no-repeat;
      width: 20px; height: 20px; overflow: hidden;
      line-height: 10000%;
    }
    .actions {
      display: flex; align-items: center; justify-content: flex-end; 
      grid-gap: 10px;
    }
  }
}
.sort.asc::after {
  content: '▲';
  margin-left: 5px;
  font-size: 11px;
}

.sort.desc::after {
  content: '▼';
  margin-left: 5px;
  font-size: 11px;
}
input::placeholder {
  color: #214695; /* Change this to your desired color */
  opacity: 1; /* Set opacity to 1 if you want to fully apply the color */
}
.btn-reset, .btn-action {
  float: left; color: #FFF; white-space: nowrap; font-size: 10px; 
  border: 1px solid #0039B0; padding: 2px 15px; 
  border-radius: 9px; text-decoration: none; background: transparent;
  height: 30px; cursor: pointer; margin-right: 10px;
  &.btn-save {
    border-color: #3b791e;
  }
  &.btn-cancel {
    opacity: 0.5;
  }
}
.avatar-upload {
  float: left; width: 100%; margin-bottom: 15px;
  input {
    width: 100%;
  }
}
.tooltip {
  position: absolute; bottom: 35px; left: 0px; transform: translateX(10px); background-color: #FFF; 
  padding: 5px; border-radius: 4px; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); z-index: 10; width: 250px;
}
.tooltip-image {
  width: 100%; height: auto;
}
.auto-drop {
  margin-bottom: 15px; padding-top: 20px;
  input {
    border: none; margin-bottom: 0px !important;
  }
}
.MuiPopper-root {
  z-index: 80000000000 !important;
}
.date-dm {
  float: left; font-weight: 500; padding-left: 20px;
  background: url('../images/ico-date.svg') left center no-repeat;
  background-size: 15px;align-items: center;
  font-size: 11px; padding-top: 2px; margin-right: 10px;
}
.date-time {
  float: left; font-weight: 500; padding-left: 20px;
  background: url('../images/ico-time.svg') left center no-repeat;
  background-size: 15px;align-items: center;
  font-size: 11px; padding-top: 2px; opacity: 0.5;
}
.btn-small {
  color: #FFF; font-size: 11px; font-style: italic; 
}

@import "index.scss";
@import "admin.scss";
@import "responsive.scss";
