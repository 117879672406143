.contact-form {
  width: 100%; margin-left: auto;
  background: #001D59; display: flex;
  flex-direction: column; padding: 40px;
  margin-bottom: 50px; margin-top: 100px;

  .description {
    width: 100%; margin-bottom: 20px;
    color: #FFF;
  }
  input[type=text] {
    font-family: "Inter"; font-style: normal; font-weight: 300; 
    font-size: 16px; line-height: 24px; color: #FFFFFF; background: transparent; 
    border: 1px solid #0048E0; border-radius: 8px; padding: 8px 15px; width: 100%;
    margin-bottom: 15px;
  }
}