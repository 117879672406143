.delete-popup {
	position: fixed; top: 0px; left: 0px;
	right: 0px; bottom: 0px; z-index: 9999999;
	display: flex; align-items: center; justify-content: center;
	display: none;
	&.active {
		display: flex;
	}
	.bg-close {
		position: absolute; left: 0px; right: 0px; 
		bottom: 0px; top: 0px; background: #000000d9;
	}
	.popup-outer {
		background: linear-gradient(90deg, #14236F 0%, #141836 100%); border-radius: 16px; padding: 20px;
		z-index: 500; position: relative; width: 100%;
		max-width: 450px; max-height: calc(100vh - 40px);
    	overflow: auto; overflow-y: auto; overflow-x: hidden;
		.btn-close {
			position: absolute; top: 20px; right: 20px;
			background: url('images/btn-close.svg') center center no-repeat;
			width: 20px; height: 20px; background-size: 20px 20px;
			cursor: pointer;
		}
		.popup-inner {
			width: 100%; float: left; margin-top: 5px;
			.delete-message {
				padding: 20px 20px; border-radius: 9px; 
				background: #351452 url(images/ico-info.svg) left 20px center no-repeat; 
				color: #FFF; padding-left: 70px; margin-bottom: 20px; border: 1px solid #EF466F;
			}
			.delete-areyousure {
				line-height: 1.4em; font-weight: 300;
			}
		}
		.popup-actions {
			margin-top: 20px; width: 100%; float: left;
			display: flex; flex-direction: row;
			.btn-cancel {
				padding: 15px; border-radius: 9px; color: #9bb7f1;
    		text-align: center; border: 1px solid #0052fe3d;
				font-weight: 500; font-size: 14px; height: 45px;
				width: 100%; cursor: pointer; float: left; 
				display: flex; align-items: center; margin-bottom: 0px;
				background: transparent;
			}
			.btn-delete {
				padding: 15px; border-radius: 9px; color: #FFF; 
				text-align: center;  font-family: var(--f-secfont); 
				float: right;  font-weight: 500; font-size: 14px; 
				height: 45px; margin-right: 10px; cursor: pointer;
				background: #FF4A4A; width: auto;
				display: flex; align-items: center;
				margin-bottom: 0px; width: 100% !important;
			}
		}
	}
}