.ribbon-wrapper {
  width: 85px; height: 88px; overflow: hidden; position: fixed; 
  top: -3px; right: -3px; z-index: 500000;
  .ribbon {
    font: bold 15px Sans-Serif; color: #fff; text-align: center; 
    text-shadow: rgba(255,255,255,0.5) 0px 1px 0px; -webkit-transform: rotate(45deg); 
    -moz-transform: rotate(45deg); -ms-transform: rotate(45deg); -o-transform: rotate(45deg); 
    position: relative; padding: 7px 0; left: -10px; top: 22px; width: 120px; 
    background-color: #ea181e; text-transform: uppercase;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ea181e), to(#b90005)); 
    background-image: -webkit-linear-gradient(top, #ea181e, #b90005); 
    background-image: -moz-linear-gradient(top, #BFDC7A, #8EBF45); 
    background-image: -ms-linear-gradient(top, #BFDC7A, #8EBF45); 
    background-image: -o-linear-gradient(top, #BFDC7A, #8EBF45); 
    color: #fff; -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3); font-size: 11px;
    -moz-box-shadow: 0px 0px 3px rgba(0,0,0,0.3); box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    &:before,
    &:after{
      content: ""; border-top: 3px solid #b90005; border-left: 3px solid transparent; 
      border-right: 3px solid transparent; position:absolute; bottom: -3px;
    }
    &:before{
      left: 0;
    }
    &:after{
      right: 0;
    }
    &.gold {
      background-color: #1e88ba;
    }
    &.superadmin {
      background-color: #ffa700;
    }
  }
}




