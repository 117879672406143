.metrowave-wrapper {
  width: 100%;
  #wave-audio {
    width: 100%; margin-bottom: 30px;
    ::part(region-handle-right) {
      border-right-width: 1px !important;
      border-right-color: #75a1ff !important;
    }
    ::part(region-handle-left) {
      border-left-width: 1px !important;
      border-left-color: #75a1ff !important;
    }
    ::part(region-content) {
      font-size: 12px;
    }
  }
  button {
    height: 30px;
  }
  .playback-wrapper {
    float: left; width: 100%; display: flex; align-items: center;
    margin-bottom: 30px;
  }
}
