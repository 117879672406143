.upload-file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  float: left;
}

.upload-progress {
  width: 100%;
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-top: 10px;
  position: relative;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
  border-radius: 5px;
  transition: width 0.2s ease;
}

input[type="file"] {
  margin: 10px 0;
}

.upload-instructions {
  font-size: 14px;
  color: #868484;
  margin-top: 10px;
  font-style: italic;

  strong {
    color: #3030d1; // Emphasize strong text
  }
}
